import ApiService from "@/core/services/api.service";

export default {

    // -----API BUG REPORT------
    getBugReport(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/bug-report/histori", { params: credentials })
    },
    getBugReportSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/bug-report/histori/single", { params: credentials })
    },

    addBugReport(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/bug-report", credentials)
    },
    closedBugReport(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/bug-report", credentials)
    },
    addReplyBugReport(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/bug-report/reply", credentials)
    },
}