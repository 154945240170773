<template>
  <div id="bug-report-histori-view" data-app>
    
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/bug-report/histori"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              Informasi Tiket
            </a>
          </li>
        </ul>
      </div>

      <!--begin::View Data Farm-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="mx-5" v-if="dataView != null">
								<!--begin::Header-->
								<div class="d-flex align-items-center pb-4">
									<!--begin::Symbol-->
									<div class="symbol symbol-40 symbol-light-success mr-5">
										<span class="symbol-label">
											<img :src="picture(dataView.created_by.biodata.avatar)" class="h-75 align-self-center" alt="Avatar" />
										</span>
									</div>
									<!--end::Symbol-->
									<!--begin::Info-->
									<div class="d-flex flex-column flex-grow-1">
										<a class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{{ dataView.created_by.biodata.fullname }}</a>
										<span class="text-muted font-weight-bold">{{ dataView.tanggal | timeAgo }}</span>
									</div>
									<!--end::Info-->
								</div>
								<!--end::Header-->

								<!--begin::Body-->
								<div>
									<!--begin::Text-->
                  <div class="p-4 mb-5 border rounded">
                    <div class="d-flex flex-wrap">
                      <span class="text-dark-75 mr-3 font-size-lg font-weight-bolder">{{ dataView.subjek | title }}</span>
                      <b-badge class="ml-sm-auto" :variant="dataView.sts.color">{{ dataView.sts.uraian }}</b-badge>
                    </div>
                    <!--begin::Separator-->
										<div class="separator separator-solid my-4"></div>
										<!--end::Separator-->
									  <span class="text-dark-75 font-size-lg font-weight-normal">{{ dataView.deskripsi }}</span>
                  </div>
									
									<!--end::Text-->
									<!--begin::Action-->
									<div class="d-flex align-items-center mb-5">
										<a class="btn btn-hover-text-primary btn-hover-icon-primary btn-sm btn-text-dark-50 bg-light-primary rounded font-weight-bolder font-size-sm p-2 mr-2">
                      <span class="svg-icon svg-icon-md svg-icon-primary pr-2">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Communication/Group-chat.svg"/>
                        <!--end::Svg Icon-->
                      </span>
                      {{ dataView.reply.length | nominal }}
                    </a>
                    <b-button v-if="dataView.status == 151" @click="openConfirm = true" variant="danger" class="font-weight-bolder" size="sm">Closed</b-button>
                    <b-button v-if="dataView.status == 11" @click="openConfirm = true" variant="info" class="font-weight-bolder" size="sm">Open</b-button>
                    <b-button v-if="dataView.status == 201" variant="secondary" class="font-weight-bolder" disabled size="sm">Closed</b-button>
									</div>
									<!--end::Action-->
                  
                  <template v-for="(rinc, index) in dataView.reply">
                    <!--begin::Separator-->
                    <div v-if="index <= dataView.reply.length" :key="`sperator-${index}`" class="separator separator-solid my-3"></div>
                    <!--end::Separator-->

                    <!--begin::Item-->
                    <div :key="`data-${index}`" class="d-flex p-2 px-sm-7">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-40 symbol-light-success mr-5 mt-1">
                        <span class="symbol-label">
                          <img :src="picture(rinc.avatar)" class="h-75 align-self-center" alt="Avatar" />
                        </span>
                      </div>
                      <!--end::Symbol-->
                      <!--begin::Info-->
                      <div class="d-flex flex-column flex-row-fluid">
                        <!--begin::Info-->
                        <div class="d-flex align-items-center flex-wrap">
                          <a href="#" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{ rinc.created_by.biodata.fullname | title }}</a>
                          <span class="text-muted font-weight-normal flex-grow-1 font-size-sm">{{ rinc.tanggal | timeAgo }}</span>
                        </div>
                        <span class="text-dark-75 font-size-sm font-weight-normal pt-1">{{ rinc.comment }}</span>
                        <!--end::Info-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Item-->
                  </template>
                    <!--begin::Separator-->
										<div class="separator separator-solid mt-6 mb-4"></div>
										<!--end::Separator-->
										<!--begin::Editor-->
										<ValidationObserver ref="formAdd">
                    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="position-relative">
                      <validationProvider name="Reply" rules="required" v-slot="{ valid, errors }">
											  <b-form-input v-model="reply" class="form-control border-0 p-0 pr-10 resize-none" placeholder="Reply..."></b-form-input>
                        <span v-if="valid">{{ errors[0] }}</span>
                      </validationProvider>
											<div class="position-absolute top-0 right-0 mt-n1 mr-n2">
												<button ref="kt_submit" type="submit" class="btn btn-icon btn-sm btn-hover-light-primary">
                          <span class="svg-icon svg-icon-md p-0">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/Communication/Send.svg"/>
                            <!--end::Svg Icon-->
                          </span>
												</button>
                        <span class="btn btn-icon btn-sm btn-hover-light-info">
                          <span class="svg-icon svg-icon-md p-0">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/General/Attachment2.svg"/>
                            <!--end::Svg Icon-->
                          </span>
												</span>
											</div>
										</b-form>
                    </ValidationObserver>
										<!--edit::Editor-->
								</div>
								<!--end::Body-->
							</div>

              <div v-else>
                <FacebookLoader></FacebookLoader>
              </div>

            </b-tab>
          </b-tabs>
        </div>

    </div>

    <v-dialog
    v-model="openConfirm"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Aksi Tiket</v-card-title>

        <v-card-text>
          Apakah anda yakin?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="openConfirm = false">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onConfirm">
            LANJUTKAN
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { FacebookLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import BugReportService from "@/core/services/api/other/bugreport.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "bug-report-histori-view",
  props: {
    idB: {
      required: true
    }
  },
  components: {
    FacebookLoader
  },
  data() {
    return {
      tabIndex: 0,
      show: true,
      openConfirm: false,
      reply: null,
      dataView: null,
    }
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },

    getData () {
      BugReportService.getBugReportSingle({ _id: this.idB })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataView = res.data.data
        })
        .catch((err) => ErrorService.status(err))
    },
    picture(file) {
      const url = process.env.BASE_URL + "media/users/"
      if (file) {
        return  url + file
      }
      return url + "default.png"
    },
    downloadFile(file) {
      if (file) {
        window.open(`${process.env.VUE_APP_API_URL}/dokumen/bug-report/${file}`, "_blank"); 
      }
    },
    onConfirm () {
      BugReportService.closedBugReport({ _id: this.dataView._id })
        .then((res) => {
            if (res.data.status) { 
              this.getData()              
            }
            ErrorService.message(res.data)
            this.openConfirm = false
        })
        .catch((err) => {
          this.openConfirm = false
          return ErrorService.status(err)
        })
    },
    onSubmit(evt) {
        evt.preventDefault()
        this.$refs.formAdd.validate().then(success => {
            if (!success) {
                ErrorService.message({
                    action: 'Peringatan',
                    message: 'Silahkan lengkapi form data terlebih dahulu',
                })
                return;
            }

            // dummy delay
            // setTimeout(() => {
                BugReportService.addReplyBugReport({ _id: this.dataView._id, message: this.reply })
                .then((res) => {
                    if (res.data.status) { 
                      this.onReset()
                      this.getData()
                    }
                    ErrorService.message(res.data)
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  return ErrorService.status(err)
                })

            // }, 800);
        })
    },
    onReset () {
        this.reply = null

        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bug Report" }]);
    this.getData()
  },
};
</script>